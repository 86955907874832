import { PageTemplate } from 'src/components/Template';
import { DataTable } from 'src/components/DataTable';
import { growthApi } from 'src/services';
import { formatDate, isCustomDomain } from 'src/utils';
import { Action, Button, ControlledInput, Switch } from 'src/components/Form';
import { useEffect, useState } from 'react';
import { Role, User } from 'src/types';
import { Flex, Spacing, Text } from 'src/components/Layout';
import { useForm } from 'react-hook-form';
import { useAppDispatch, userSlice } from 'src/store';
import { useDataTable, useRole } from 'src/hooks';
import { Archive } from 'src/components/Archive';
import { TeamInviteModal } from './TeamInviteModal';
import styled from 'styled-components';
import { NewUserModal } from './NewUserModal';
import { EditUserAccessModal } from './EditUserAccessModal';

const { useUsersQuery, useProfileQuery, useUpdateUserProfileMutation } = growthApi;

type UsersFormValues = {
  search?: string;
};

export const Users = () => {
  const { isAdmin, isWhiteLabel } = useRole();
  const { data, isFetching, error, refetch } = useUsersQuery({});
  const { data: profile, refetch: profileRefetch } = useProfileQuery();
  const dispatch = useAppDispatch();
  const [dspBindingIsOpen, setDSPBindingIsOpen] = useState<boolean>(false);
  const [createIsOpen, setCreateIsOpen] = useState<boolean>(false);
  const [teamInviteIsOpen, setTeamInviteIsOpen] = useState<boolean>(false);
  const [updateUserProfile] = useUpdateUserProfileMutation();
  const { control, watch } = useForm<UsersFormValues>();
  const values = watch();

  useEffect(() => {
    if (profile) {
      dispatch(userSlice.actions.updateUser(profile.data));
    }
  }, [dispatch, profile]);

  const { dataTableProps, selectedRow, setSelectedRow, rowStatus, changeRowStatus } = useDataTable({
    idKey: 'id',
    data: data?.data,
    isLoading: isFetching,
    error,
    search: values.search,
    searchKeys: ['id', 'email', 'user_name', 'agency_name', 'advertiser_name'],
    defaultSort: {
      key: 'id',
      direction: 'desc',
    },
    sortNumberKeys: ['is_confirmed', 'is_admin'],
    onChangeRowStatus: async (id: any, field: string, value: any) => {
      await updateUserProfile({
        user_id: id,
        [field]: value,
      }).unwrap();
    },
    columns: [
      { header: 'ID', accessor: 'id', sortable: true },
      { header: 'Email', accessor: 'email', sortable: true },
      {
        header: 'Access',
        accessor: '_access',
        render: (_, row: any) => {
          if (row.is_admin) {
            return 'All';
          }
          return (
            <Flex gap="sm">
              <Flex gap="xs" align="center">
                {isAdmin && row.is_wl_agency && <WLIcon>WL</WLIcon>}
                <Text>
                  {row.agency_name
                    ? row.advertiser_name
                      ? `${row.agency_name} (${row.advertiser_name})`
                      : row.agency_name
                    : '-'}
                </Text>
              </Flex>
              <Action
                icon="edit"
                onClick={() => {
                  setSelectedRow(row);
                  setDSPBindingIsOpen(true);
                }}
              />
            </Flex>
          );
        },
        when: (user?: User) => [Role.Admin, Role.WhiteLabel, Role.Agency].includes(user?.role),
      },
      {
        header: 'Promo Code',
        accessor: 'promo_code',
        render: (value: any) => (value ? value : '-'),
        when: (user?: User) => [Role.Admin].includes(user?.role),
      },
      {
        header: 'Confirmed',
        accessor: 'is_confirmed',
        sortable: true,
        render: (value: any, row: User) => (
          <Switch
            value={rowStatus[row.id]?.is_confirmed ?? Boolean(Number(value))}
            onChange={(value) => changeRowStatus(row.id, 'is_confirmed', value)}
          />
        ),
        when: (user?: User) => [Role.Admin].includes(user?.role),
      },
      {
        header: 'Admin',
        accessor: 'is_admin',
        sortable: true,
        render: (value: any, row: User) => (
          <Switch
            value={rowStatus[row.id]?.is_admin ?? Boolean(Number(value))}
            onChange={(value) => changeRowStatus(row.id, 'is_admin', value)}
          />
        ),
        when: (user?: User) => [Role.Admin].includes(user?.role),
      },
      {
        header: 'View only',
        accessor: 'is_view_only',
        sortable: true,
        render: (value: any, row: User) => (
          <Switch
            value={rowStatus[row.id]?.is_view_only ?? Boolean(Number(value))}
            onChange={(value) => changeRowStatus(row.id, 'is_view_only', value)}
          />
        ),
        when: (user?: User) => [Role.Admin, Role.WhiteLabel].includes(user?.role),
      },
      { header: 'Created At', accessor: 'created_at', render: formatDate, sortable: true },
      {
        header: '',
        accessor: 'action',
        render: (_: any, user: User) => (
          <Flex gap="md">
            <Archive type="user" typeId={user.id} onSuccess={() => refetch()} />
          </Flex>
        ),
      },
    ],
  });

  return (
    <PageTemplate>
      <Flex justify="space-between" align="center">
        <Flex align="center" gap="lg">
          <Text size="xxl" weight={700}>
            Users
          </Text>
          <ControlledInput width="30rem" prefix="Search:" name="search" control={control} placeholder="Keyword" />
        </Flex>
        <Flex gap="md">
          {!isCustomDomain() && (
            <Button width="16rem" various="secondary" onClick={() => setTeamInviteIsOpen(true)}>
              TEAM INVITE
            </Button>
          )}
          <Button width="16rem" shadow onClick={() => setCreateIsOpen(true)}>
            NEW USER
          </Button>
        </Flex>
      </Flex>
      <Spacing size="xl" />
      <DataTable {...dataTableProps} />
      <EditUserAccessModal
        isOpen={dspBindingIsOpen}
        user={selectedRow}
        onSuccess={() => {
          refetch();
          profileRefetch();
        }}
        onClose={() => setDSPBindingIsOpen(false)}
      />
      <NewUserModal
        isOpen={createIsOpen}
        onSuccess={() => {
          refetch();
        }}
        onClose={() => setCreateIsOpen(false)}
      />
      <TeamInviteModal isOpen={teamInviteIsOpen} onClose={() => setTeamInviteIsOpen(false)} />
    </PageTemplate>
  );
};

const WLIcon = styled.div`
  color: white;
  background: ${(props) => props.theme.color.primary};
  font-size: 1rem;
  font-weight: 600;
  border-radius: 0.2rem;
  padding: 0.15rem 0.3rem;
`;
