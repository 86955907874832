import { PageTemplate } from 'src/components/Template';
import { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { dateToString, emptyStringToNull, getApiErrorMessage, stringToDate } from 'src/utils';
import { usePersistentAgencyAdvertiser, useToast } from 'src/hooks';
import { cloneDeep } from 'lodash';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { growthApi } from 'src/services';
import { OrganizeCampaign } from './OrganizeCampaign';
import { SetCampaignObjective } from './SetCampaignObjective';
import { CampaignLaunch } from './CampaignLaunch';
import {
  CampaignBillingType,
  CampaignChannel,
  CampaignConversionLocationType,
  CampaignDeliveryFrequencyInterval,
  CampaignEngagement,
  CampaignObjective,
  CampaignOngoingFlight,
  CampaignOngoingInterval,
  CampaignPacing,
} from 'src/types';
import { nanoid } from 'nanoid';
import { calcBudgetAndDate } from './utils';

const { useCampaignsQuery, useCreateOrUpdateCampaignMutation } = growthApi;

enum CampaignEditStep {
  Organize = 1,
  Objective = 2,
  Launch = 3,
}

const CAMPAIGN_DEFAULT_ONGOING_FLIGHTS: CampaignOngoingFlight[] = [{ id: nanoid() }];

export type CampaignEditFormValues = {
  id?: number;
  agency_id?: number;
  advertiser_id?: number;
  channel?: CampaignChannel;
  campaign_name?: string;
  objective?: CampaignObjective;
  audience_id?: number;
  creative_ids?: number[];
  pixel_uuid?: string;
  budget_amount?: number;
  budget_amount_daily?: number;
  budget_pacing?: string;
  budget_max_bid_cpm?: number;
  budget_billing_type?: CampaignBillingType;
  budget_billing_target?: number;
  budget_billing_roi_avg_order_value?: number;
  schedule_start_date?: Date;
  schedule_end_date?: Date;
  schedule_timezone?: string;
  delivery_frequency_amount?: number;
  delivery_frequency_interval?: CampaignDeliveryFrequencyInterval;
  ongoing_enabled: boolean;
  ongoing_interval?: CampaignOngoingInterval;
  ongoing_interval_budget?: number;
  ongoing_flights?: CampaignOngoingFlight[];
  is_active?: boolean;
  is_draft?: boolean;
  // meta
  meta?: any;
  // custom fields
  engagement: CampaignEngagement;
};

export const CampaignEdit = () => {
  const { agencyId, advertiserId } = usePersistentAgencyAdvertiser();
  const [searchParams, setSearchParams] = useSearchParams();
  const id = searchParams.get('id');
  const isNew = !id;
  const copy = searchParams.get('copy');
  const urlStep = searchParams.get('step');
  const location = useLocation();
  const { data, isLoading: isDetailLoading, error } = useCampaignsQuery({ id: id || copy }, { skip: !id && !copy });
  const isDraft = Boolean(data?.data?.is_draft);
  const [step, setStep] = useState<CampaignEditStep>(urlStep ? Number(urlStep) : CampaignEditStep.Organize);
  const defaultValues: CampaignEditFormValues = useMemo(
    () => ({
      agency_id: agencyId,
      advertiser_id: advertiserId,
      objective: CampaignObjective.Awareness,
      campaign_name: '',
      is_draft: false,
      is_active: true,
      ongoing_enabled: false,
      ongoing_flights: CAMPAIGN_DEFAULT_ONGOING_FLIGHTS,
      budget_pacing: CampaignPacing.SpendEvenly,
      budget_billing_type: CampaignBillingType.CTR,
      engagement: CampaignEngagement.NoEngagement,
      meta: {
        conversion_location_type: CampaignConversionLocationType.Website,
        customize_placements: [null],
        device: null,
      },
    }),
    [agencyId, advertiserId],
  );
  const useFormReturns = useForm<CampaignEditFormValues>({
    defaultValues,
  });
  const { watch, setValue, handleSubmit, reset } = useFormReturns;
  const values = watch();
  const [createOrUpdateCampaign] = useCreateOrUpdateCampaignMutation();
  const navigate = useNavigate();
  const { showSuccessToast, showErrorToast } = useToast();
  const enableDraft = isNew || isDraft;

  useEffect(() => {
    if (location.state?.campaign) {
      reset({
        ...defaultValues,
        ...location.state?.campaign,
      });
    }
  }, [defaultValues, location.state?.campaign, reset]);

  useEffect(() => {
    if (data?.data) {
      const campaign = cloneDeep(data.data);
      if (copy) {
        campaign.campaign_name = `${campaign.campaign_name} copy`;
        delete campaign.id;
      }
      reset({
        ...campaign,
        creative_ids: campaign.creative_ids?.split(',').map(Number),
        schedule_start_date: stringToDate(campaign.schedule_start_date),
        schedule_end_date: stringToDate(campaign.schedule_end_date),
        ongoing_flights: campaign.ongoing_flights?.map((flight: any) => ({
          ...flight,
          start_date: stringToDate(flight.start_date),
          end_date: stringToDate(flight.end_date),
        })),
        engagement: campaign.pixel_uuid ? CampaignEngagement.Pixel : CampaignEngagement.NoEngagement,
      });
    }
  }, [copy, data?.data, reset]);

  useEffect(() => {
    searchParams.set('step', String(step));
    setSearchParams(searchParams.toString(), { replace: true });
  }, [searchParams, setSearchParams, step]);

  const onInnerSubmit = async (values: CampaignEditFormValues) => {
    try {
      const { budget, startDate, endDate } = calcBudgetAndDate(values);
      await createOrUpdateCampaign({
        ...values,
        creative_ids: values.creative_ids?.join(','),
        budget_amount: budget,
        budget_amount_daily: emptyStringToNull(values.budget_amount_daily),
        budget_max_bid_cpm: emptyStringToNull(values.budget_max_bid_cpm),
        budget_billing_target: emptyStringToNull(values.budget_billing_target),
        budget_billing_roi_avg_order_value: emptyStringToNull(values.budget_billing_roi_avg_order_value),
        delivery_frequency_amount: emptyStringToNull(values.delivery_frequency_amount),
        ongoing_interval_budget: emptyStringToNull(values.ongoing_interval_budget),
        schedule_start_date: dateToString(startDate),
        schedule_end_date: dateToString(endDate),
        ongoing_flights: values.ongoing_flights?.map((flight: CampaignOngoingFlight) => ({
          ...flight,
          start_date: dateToString(flight.start_date),
          end_date: dateToString(flight.end_date),
        })),
      }).unwrap();
      if (isNew) {
        showSuccessToast(values.is_draft ? 'Create campaign draft successfully' : 'Create campaign successfully');
        navigate('/activate/campaigns');
      } else {
        showSuccessToast(values.is_draft ? 'Save campaign draft successfully' : 'Save campaign successfully');
        navigate('/activate/campaigns');
      }
    } catch (error) {
      showErrorToast(getApiErrorMessage(error));
    }
  };

  const onSubmit = () => {
    setValue('is_draft', false);
    handleSubmit(onInnerSubmit)();
  };

  const onDraftSubmit = () => {
    const errors = [];
    if (!values.agency_id) {
      errors.push('Agency is required');
    }
    if (!values.advertiser_id) {
      errors.push('Advertiser is required');
    }
    if (!values.campaign_name) {
      errors.push('Campaign name is required');
    }
    if (errors.length > 0) {
      showErrorToast(errors);
      return;
    }
    setValue('is_draft', true);
    handleSubmit(onInnerSubmit)();
  };

  return (
    <PageTemplate isLoading={isDetailLoading} error={getApiErrorMessage(error)}>
      <FormProvider {...useFormReturns}>
        <>
          {step === CampaignEditStep.Organize && (
            <OrganizeCampaign
              onNext={() => setStep(CampaignEditStep.Objective)}
              enableDraft={enableDraft}
              onDraftSubmit={onDraftSubmit}
            />
          )}
          {step === CampaignEditStep.Objective && (
            <SetCampaignObjective
              onBack={() => setStep(CampaignEditStep.Organize)}
              onNext={() => setStep(CampaignEditStep.Launch)}
              enableDraft={enableDraft}
              onDraftSubmit={onDraftSubmit}
            />
          )}
          {step === CampaignEditStep.Launch && (
            <CampaignLaunch
              onBack={() => setStep(CampaignEditStep.Objective)}
              onSubmit={onSubmit}
              enableDraft={enableDraft}
              onDraftSubmit={onDraftSubmit}
            />
          )}
        </>
      </FormProvider>
    </PageTemplate>
  );
};
